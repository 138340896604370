.stepper-label {
    color: white !important;
    font-size: 1rem !important;
    font-weight: bold !important;
}

.stepper-btn {
    color: white !important;
    font-size: 1rem !important;
    font-weight: bold !important;
}
.assets-table {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 5%;
}

.assets-table > table {
    color: white !important
}

.MuiInputBase-root {
    color: white !important;
}

.MuiIconButton-root {
    color: white !important
}
.MuiTableCell-root {
    color: white !important;
}

.MuiTypography-caption {
    color: white !important
}

.MuiSelect-icon {
    color: white !important
}